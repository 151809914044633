import React, { FormEventHandler, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faMobileAlt,
  faAt,
} from "@fortawesome/free-solid-svg-icons";
import "./Contact.css";
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {
  const recaptchaRef = useRef<any>();
  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_KEY;

  
  const onSubmit = (e: any/* FormEventHandler<HTMLFormElement> */) => {
    e.preventDefault();
    recaptchaRef.current.execute();
  };


  return (
    <div className="Contact" id="contact">
      <h1>Contact Us</h1>
      <div className="Contact-column">
        <div className="Contact-left">
          <form onSubmit={onSubmit}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={recaptchaKey!}
              onChange={() => submitForm()}
            />
            <h3>Contact Us</h3>
            <label htmlFor="name" className="Contact-label">
              Your Name
            </label>
            <input
              type="text"
              placeholder="Name"
              className="Contact-input"
              id="name"
            />
            <label htmlFor="email" className="Contact-label">
              Your Email
            </label>
            <input
              type="text"
              placeholder="Email"
              className="Contact-input"
              id="email"
            />
            <label htmlFor="message" className="Contact-label">
              Your Message
            </label>
            <input
              type="text"
              placeholder="Message"
              className="Contact-input"
              id="message"
            />
            <input type="submit" className="Contact-button g-recaptcha" value="Send" disabled={true}></input>
          </form>
        </div>
        <div className="Contact-right">
          <div className="Contact-right-top">
            <div className="Contact-info">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="Contact-info-logo"
              />
              <p>85051 Ingolstadt</p>
            </div>
            <div className="Contact-info">
              <FontAwesomeIcon
                icon={faMobileAlt}
                className="Contact-info-logo"
              />
              <p>on request</p>
            </div>
            <div className="Contact-info">
              <FontAwesomeIcon icon={faAt} className="Contact-info-logo" />
              <p>contact@smarter-contracts.net</p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );


  function submitForm() {
    //!TODO

    console.log("Submitted");
  }
}

export default Contact;
