import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

function TwitterTimelineSection() {
  return (
    <div>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="smrtcontracts"
        options={{ height: 400 }}
      />
    </div>
  );
}

export default TwitterTimelineSection;
