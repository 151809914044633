import React, { useRef, useState } from "react";
import { auth, logout, signInEmail, signInWithGoogle, signUpEmail } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function SignInPage() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [user] = useAuthState(auth);

  const [loading, setLoading] = useState(false);

  async function handleSignIn() {
    if (emailRef?.current && passwordRef?.current) {
      setLoading(true);
      try {
        await signInEmail(emailRef.current?.value, passwordRef.current?.value);
      } catch {
        alert("Error");
      }
      setLoading(false);
    }
  }
  async function handleSignUp() {
    if (emailRef?.current && passwordRef?.current) {
      setLoading(true);
      try {
        await signUpEmail(emailRef.current?.value, passwordRef.current?.value);
      } catch {
        alert("Error");
      }
      setLoading(false);
    }
  }
  async function handleGoogleSignIn() {
    setLoading(true);
    await signInWithGoogle();
    setLoading(false);
  }

  function showLoginButtons() {
    return loading || user != null;
  }

  async function handleSignOut() {
    setLoading(true);
    try {
      await logout();
    } catch {
      alert("Error!");
    }
    setLoading(false);
  }

  return (
    <div>
      <h1>Currently Logged in as: {user?.email}</h1>
      <input ref={emailRef} placeholder="Email"></input>
      <input ref={passwordRef} placeholder="Password" type="password"></input>
      <button disabled={showLoginButtons()} onClick={handleSignIn}>
        Sign in
      </button>
      <button disabled={showLoginButtons()} onClick={handleSignUp}>
        Sign up
      </button>

      <button disabled={showLoginButtons()} onClick={handleGoogleSignIn}>
        Sign in with Google
      </button>
      <button disabled={loading || !user} onClick={handleSignOut}>
        Sign Out
      </button>
    </div>
  );
}

export default SignInPage;
