import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsletterImg from "../assets/img/AboutUsImg.webp"
import NewsletterForm from "./NewsletterForm";
import "./NewsletterSection.css";

function NewsletterSection() {
  
  const mailChimpUrl = "https://gmail.us10.list-manage.com/subscribe/post?u=c2c3bcb0e8c70edba97cb1ad9&amp;id=e9fcc1fde5&amp;f_id=001029e2f0";

  return (
    <div className="NewsletterSection" id="newsletter">
      <div className="NewsletterSection-image">
        <img src={NewsletterImg} alt="Placeholder" />
      </div>
      <div className="NewsletterSection-right">
        <div>
          <h1 className="Newsletter-title">Always stay updated</h1>
          <h3 className="Newsletter-call-to-action">
            Subscribe to our Newsletter
          </h3>
        </div>

        <MailchimpSubscribe
      url={ mailChimpUrl }
      render={ ( props ) => {
        const { subscribe, status, message } = props || {};
        return (
          <NewsletterForm
            status={ status }
            message={ message }
            onValidated={ (formData: any) => subscribe( formData ) }
          />
          );
        } }
      />
      </div>
    </div>
  );
}

export default NewsletterSection;
