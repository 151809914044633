import React from 'react'
import { brotliDecompress } from 'zlib';
import "./StyledButton.css"


function StyledButton(props: any) {




  return (
    <button className={"StyledButton " + props.className} onClick={props.onClick} >{props.children}</button>
  )
}

export default StyledButton