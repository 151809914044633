import React from "react";
import AboutUs from "../components/AboutUs";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import NewsletterSection from "../components/NewsletterSection";
import PricingSection from "../components/PricingSection";
import Header from "../components/Header";
import TwitterTimelineSection from "../components/TwitterTimelineSection";

function HomePage() {
  return (
    <div className="App">
      <header>
        <Header/>
      </header>
      <AboutUs />
      <Contact />
      <NewsletterSection />
      <TwitterTimelineSection/>
      <Footer />
    </div>
  );
}

export default HomePage;
