import React from "react";
import "./Header.css";
import Navbar from "./Navbar";
import headerImage from "../assets/img/Header-image-resize.jpg";
import StyledButton from "./StyledButton";
import Popup from "reactjs-popup";
/* import "reactjs-popup/dist/index.css"; */
import "./Popup.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsletterForm from "./NewsletterForm";
import Logo from "../assets/img/Logo.png";

function Header() {
  const headerStyle = {
    backgroundImage: { headerImage },
  };

  const mailChimpUrl =
    "https://gmail.us10.list-manage.com/subscribe/post?u=c2c3bcb0e8c70edba97cb1ad9&amp;id=e9fcc1fde5&amp;f_id=001029e2f0";

  return (
    <div
      className="Header"
      id="home"
      style={{ backgroundImage: `url(${headerImage})` }}
    >
      <div className="Header-Left">
        <h1 className="Header-Title">
          The Simple way to integrate Blockchain in your business
        </h1>

        <Popup
          trigger={
            <StyledButton className="Header-Actionbtn">
              Try for Free
            </StyledButton>
          }
          modal
          nested
        >
          <div className="modal">

            <div className="header"> Coming Soon </div>
            <div className="content">
              <img src={Logo} style={{ width: "50%" }} />
              <br />
              The Smarter Contracts Web interface is currently in Development.
              If you want to be notified when we release the first beta,
              subscribe to our newsletter.
            </div>
            <div className="actions">
              <MailchimpSubscribe
                url={mailChimpUrl}
                render={(props) => {
                  const { subscribe, status, message } = props || {};
                  return (
                    <NewsletterForm
                      status={status}
                      message={message}
                      onValidated={(formData: any) => subscribe(formData)}
                    />
                  );
                }}
              />
            </div>
          </div>
        </Popup>
      </div>
      <div className="Header-Right"></div>
    </div>
  );
}

export default Header;
