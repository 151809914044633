import "./App.css";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";

function App() {


  return (
    <div className="App">
      <div id="popup-root" />

      <Navbar />
      <HomePage />
    </div>
  );
}

export default App;
