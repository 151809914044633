import React from "react";
import aboutUsImg from "../assets/img/AboutUsPic.webp";
import "./AboutUs.css";
import StyledButton from "./StyledButton";

function AboutUs() {
  return (
    <div className="AboutUs" id="about">
      <div className="AboutUs-Column">
        <h1 className="AboutUs-title">About Us</h1>
        <p className="AboutUs-text">
          <p>
            Smart<b>er</b> Contracts provides a simple all-in-one solution to
            everything Web3 related.
          </p>
          <p>
            We are a Startup based in Germany with the aim to make blockchain
            technology like Smart Contracts, NFTs etc. less complicated to use
            and implement.
          </p>
          <p>
            We also want people to be able to utilize the full potential of the
            blockchain and to not only see it as a hype.
          </p>
          Because of our adaptive pricing anyone from small business to
          enterprise will be able to use our products for their needs.
          <p>
            Currently we are in a closed development stage with the first beta
            releasing very soon. You can contact us or subscribe to our
            newsletter if you want to know more.
          </p>
        </p>

        <a href="#contact">
          <StyledButton className="AboutUs-LearnMore">Learn more</StyledButton>
        </a>
      </div>

      <div className="AboutUs-Image">
        <img src={aboutUsImg} alt="Placeholder"></img>
      </div>
    </div>
  );
}

export default AboutUs;
