import {
  faChevronLeft,
  faChevronRight,
  faCogs,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import "./LoginIcon.css";
import { CSSTransition } from "react-transition-group";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "../firebase";
import { Link } from "react-router-dom";

function LoginIcon(props: any) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div>
      <div className="loginIcon-Icon">
        <a onClick={() => toggleMenu()} style={{width: "100%", height: "100%", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>
          <FontAwesomeIcon icon={faUser} color="black" />
        </a>
        {showMenu && <LoginDropwdown />}
      </div>
    </div>
  );

  function toggleMenu() {
    setShowMenu(!showMenu);
    props.onChange(!showMenu);
  }
}

function LoginDropwdown() {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeigt, setMenuHeight] = useState(0);
  const dropdownRef = useRef<any>(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el: any) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props: any) {
    return (
      <a
        className="DropdownItem"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="DropdownItem-icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="DropdownItem-icon-right">{props.rightIcon}</span>
      </a>
    );
  }

  return (
    <div className="Dropdown" style={{ height: menuHeigt }} ref={dropdownRef}>
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="DropdownMenu-primary"
        onEnter={calcHeight}
      >
        <div className="menu">
          {user !== null && (
            <DropdownItem
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              goToMenu="account"
            >
              My Profile
            </DropdownItem>
          )}
          {user == null && (
            <Link to={`login`}>
              <DropdownItem leftIcon={<FontAwesomeIcon icon={faUser} />}>
                Login
              </DropdownItem>
            </Link>
          )}
          <DropdownItem
            leftIcon={<FontAwesomeIcon icon={faCogs} />}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            goToMenu="settings"
          >
            Settings
          </DropdownItem>
        </div>
      </CSSTransition>

      {/* Account Menu */}
      <CSSTransition
        in={activeMenu === "account"}
        unmountOnExit
        timeout={500}
        classNames="DropdownMenu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem
            leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            goToMenu="main"
          >
            Back
          </DropdownItem>
          <a onClick={() => logout()}>
            <DropdownItem
              leftIcon={<FontAwesomeIcon icon={faSignOut} />}
              goToMenu="main"
            >
              Sign Out
            </DropdownItem>
          </a>
        </div>
      </CSSTransition>

      {/* Settings Menu */}
      <CSSTransition
        in={activeMenu === "settings"}
        unmountOnExit
        timeout={500}
        classNames="DropdownMenu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem
            leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            goToMenu="main"
          >
            Back
          </DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}

export default LoginIcon;
