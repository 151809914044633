import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "../utilities/helper";
import {
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import LoginIcon from "./LoginIcon";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;
    console.log(dropdownOpen);
    if (!dropdownOpen) {
      setVisible(
        (prevScrollPos > currentScrollPos &&
          prevScrollPos - currentScrollPos > 70) ||
          currentScrollPos < 10
      );
    }

    setPrevScrollPos(currentScrollPos);
    // timer set to 100 milliseconds:
  }, 100);
  // new useEffect:
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <div className="navbar" style={{ top: visible ? "0" : "-120px" }}>
      <a onClick={() => toggleMenu()} className="navbar-mobile">
        <FontAwesomeIcon icon={faBars} />
      </a>
      <nav className={`navbar-nav ${navbarClass()}`}>
        <div className="navbar-container">
          <ul>
            <li className="navbar-closeButton">
              <a onClick={() => toggleMenu()}>
                <FontAwesomeIcon icon={faWindowClose} color="black" />
              </a>
            </li>
            <li>
              <a href="#home">Home</a>
            </li>

            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              <ul className="navbar-socials">
                <li>
                  <a href="https://www.linkedin.com/company/smarter-contracts/">
                    <FontAwesomeIcon icon={faLinkedin} color="black" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/SmrtContracts">
                    <FontAwesomeIcon icon={faTwitter} color="black" />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <LoginIcon onChange={onDropdownChange} />
    </div>
  );

  function toggleMenu() {
    setShowMenu(!showMenu);
    console.log(showMenu);
  }

  function navbarClass() {
    if (!showMenu) {
      return "";
    } else {
      return "navbar-visible";
    }
  }

  function onDropdownChange(state: boolean) {
    setDropdownOpen(state);
    console.log(dropdownOpen);
  }
}

export default Navbar;
