import { initializeApp } from "firebase/app";
import "firebase/firestore";
/* import { useCollectionData } from "react-firebase-hooks/firestore";
 */ import firebaseInfo from "./secrets/firebase_info";
 import { getPerformance } from "firebase/performance";


import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword
} from "firebase/auth";

const app = initializeApp({
  apiKey: firebaseInfo.apiKey,
  authDomain: firebaseInfo.authDomain,
  projectId: firebaseInfo.projectId,
  storageBucket: firebaseInfo.storageBucket,
  messagingSenderId: firebaseInfo.messagingSenderId,
  appId: firebaseInfo.appId,
  measurementId: firebaseInfo.measurementId,
});

// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);

export const auth = getAuth();

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider);
};

export function signUpEmail(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function signInEmail(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  signOut(auth);
}