import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <div className="Footer-Column">
        <div className="Footer-left">
          <ul className="Footer-links">
            <li className="Footer-link">
              <a href="#home">Home</a>
            </li>
            <li className="Footer-link">
              <a href="#about">About</a>
            </li>
            <li className="Footer-link">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className="Footer-right">
          <ul className="Footer-socials">
            <li className="Footer-social">
              <a href="https://www.linkedin.com/company/smarter-contracts/">
                <FontAwesomeIcon icon={faLinkedinIn} color="black" />
              </a>
            </li>
            <li className="Footer-social">
              <a href="https://twitter.com/SmrtContracts">
                <FontAwesomeIcon icon={faTwitter} color="black" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="Footer-copyright">
        © Smarter Contracts 2022 Copyright
      </p>
    </div>
  );
}

export default Footer;
